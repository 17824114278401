@import 'variables';

*,
*:before,
*:after {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

body {
  font-family: $font-family;
  color: $neutral-color-800;
  background-color: $neutral-color-50;
  font-size: 1rem;
  line-height: 1.4;
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  height: 100dvh;
  width: 100dvw;
}

h1,
h2,
h3,
h4 {
  margin: 0 0 1rem;
}

a {
  color: $primary-color;
  text-decoration: none;
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    color: $primary-constrast-color;
    text-decoration: underline;
  }
}

p {
  margin: 0 0 1rem;
}

#timer {
  font-size: 4rem;
}