
$tb-primary-color-50: lighten(#4b3ba8, 52);
$tb-primary-color-100: lighten(#4b3ba8, 39);
$tb-primary-color-200: lighten(#4b3ba8, 26);
$tb-primary-color-300: lighten(#4b3ba8, 13);
$tb-primary-color-400: #4b3ba8;
$tb-primary-color-500: #312852;
$tb-primary-color-600: darken(#312852, 5);
$tb-primary-color-700: darken(#312852, 10);
$tb-primary-color-800: darken(#312852, 15);
$tb-primary-color-900: darken(#312852, 20);

$tb-accent-color-50: lighten(#ea687f, 20);
$tb-accent-color-100: lighten(#ea687f, 15);
$tb-accent-color-200: lighten(#ea687f, 10);
$tb-accent-color-300: lighten(#ea687f, 5);
$tb-accent-color-400: #ea687f;
$tb-accent-color-500: #e54360;
$tb-accent-color-600: darken(#e54360, 5);
$tb-accent-color-700: darken(#e54360, 10);
$tb-accent-color-800: darken(#e54360, 15);
$tb-accent-color-900: darken(#e54360, 20);

body.ve {
  --tb-primary-color-50: #{$tb-primary-color-50};
  --tb-primary-color-100: #{$tb-primary-color-100};
  --tb-primary-color-200: #{$tb-primary-color-200};
  --tb-primary-color-300: #{$tb-primary-color-300};
  --tb-primary-color-400: #{$tb-primary-color-400};
  --tb-primary-color-500: #{$tb-primary-color-500};
  --tb-primary-color-600: #{$tb-primary-color-600};
  --tb-primary-color-700: #{$tb-primary-color-700};
  --tb-primary-color-800: #{$tb-primary-color-800};
  --tb-primary-color-900: #{$tb-primary-color-900};

  --tb-accent-color-50: #{$tb-accent-color-50};
  --tb-accent-color-100: #{$tb-accent-color-100};
  --tb-accent-color-200: #{$tb-accent-color-200};
  --tb-accent-color-300: #{$tb-accent-color-300};
  --tb-accent-color-400: #{$tb-accent-color-400};
  --tb-accent-color-500: #{$tb-accent-color-500};
  --tb-accent-color-600: #{$tb-accent-color-600};
  --tb-accent-color-700: #{$tb-accent-color-700};
  --tb-accent-color-800: #{$tb-accent-color-800};
  --tb-accent-color-900: #{$tb-accent-color-900};

  --tb-primary-color: var(--tb-primary-color-500);
  --tb-accent-color: var(--tb-accent-color-500);
  --tb-primary-constrast-color: var(--tb-primary-color-400);
  --tb-accent-constrast-color: var(--tb-accent-color-400);
  
  header {
    border-bottom: 1px solid $neutral-color-500;
  
    div {
      img.ve {
        display: block;
      }
    }
  }
}
