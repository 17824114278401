$tb-primary-color-50: lighten(#98bcd2, 26);
$tb-primary-color-100: lighten(#98bcd2, 13);
$tb-primary-color-200: #98bcd2;
$tb-primary-color-300: #6795b2;
$tb-primary-color-400: #357398;
$tb-primary-color-500: #03517e;
$tb-primary-color-600: #034164;
$tb-primary-color-700: #042436;
$tb-primary-color-800: darken(#042436, 5);
$tb-primary-color-900: darken(#042436, 10);

$tb-accent-color-50: #fefaed;
$tb-accent-color-100: #fdf0c8;
$tb-accent-color-200: darken(#fdf0c8, 5);
$tb-accent-color-300: #fad473;
$tb-accent-color-400: darken(#fad473, 5);
$tb-accent-color-500: #f6b413;
$tb-accent-color-600: #dda221;
$tb-accent-color-700: darken(#dda221, 5);
$tb-accent-color-800: darken(#dda221, 10);
$tb-accent-color-900: darken(#dda221, 15);

body.tb {
  --tb-primary-color-50: #{$tb-primary-color-50};
  --tb-primary-color-100: #{$tb-primary-color-100};
  --tb-primary-color-200: #{$tb-primary-color-200};
  --tb-primary-color-300: #{$tb-primary-color-300};
  --tb-primary-color-400: #{$tb-primary-color-400};
  --tb-primary-color-500: #{$tb-primary-color-500};
  --tb-primary-color-600: #{$tb-primary-color-600};
  --tb-primary-color-700: #{$tb-primary-color-700};
  --tb-primary-color-800: #{$tb-primary-color-800};
  --tb-primary-color-900: #{$tb-primary-color-900};

  --tb-accent-color-50: #{$tb-accent-color-50};
  --tb-accent-color-100: #{$tb-accent-color-100};
  --tb-accent-color-200: #{$tb-accent-color-200};
  --tb-accent-color-300: #{$tb-accent-color-300};
  --tb-accent-color-400: #{$tb-accent-color-400};
  --tb-accent-color-500: #{$tb-accent-color-500};
  --tb-accent-color-600: #{$tb-accent-color-600};
  --tb-accent-color-700: #{$tb-accent-color-700};
  --tb-accent-color-800: #{$tb-accent-color-800};
  --tb-accent-color-900: #{$tb-accent-color-900};

  --tb-primary-color: var(--tb-primary-color-500);
  --tb-accent-color: var(--tb-accent-color-500);
  --tb-primary-constrast-color: var(--tb-primary-color-400);
  --tb-accent-constrast-color: var(--tb-accent-color-400);
  
  header {
    background-color: $primary-color;
  
    div {
      img.tb {
        display: block;
      }
    }
  }
}
