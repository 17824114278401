$font-family: 'Open Sans', 'Helvetica Neue', sans-serif;

$primary-color: var(--tb-primary-color);
$primary-constrast-color: var(--tb-primary-constrast-color);

$primary-color-50: var(--tb-primary-color-50);
$primary-color-600: var(--tb-primary-color-600);
$primary-color-700: var(--tb-primary-color-700);

$accent-color: var(--tb-accent-color);
$accent-constrast-color: var(--tb-accent-constrast-color);

$success-color: var(--tb-success-color);
$warn-color: var(--tb-warn-color);

$neutral-color-50: var(--tb-neutral-color-50);
$neutral-color-100: var(--tb-neutral-color-100);
$neutral-color-200: var(--tb-neutral-color-200);
$neutral-color-300: var(--tb-neutral-color-300);
$neutral-color-400: var(--tb-neutral-color-400);
$neutral-color-500: var(--tb-neutral-color-500);
$neutral-color-600: var(--tb-neutral-color-600);
$neutral-color-700: var(--tb-neutral-color-700);
$neutral-color-800: var(--tb-neutral-color-800);
$neutral-color-900: var(--tb-neutral-color-900);

$warn-color-100: var(--tb-warn-color-100);
$warn-color-300: var(--tb-warn-color-300);
$warn-color-600: var(--tb-warn-color-600);
