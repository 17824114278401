
$tb-warn-color-50: #fef2f2;
$tb-warn-color-100: #e08562;
$tb-warn-color-200: lighten(#db744b, 5);
$tb-warn-color-300: #db744b;
$tb-warn-color-400: lighten(#d1521d, 5);
$tb-warn-color-500: #d1521d;
$tb-warn-color-600: #a74217;
$tb-warn-color-700: #a74217;
$tb-warn-color-800: darken(#a74217, 5);
$tb-warn-color-900: darken(#a74217, 10);

$tb-neutral-color-50: #fafafa;
$tb-neutral-color-100: #eeeeee;
$tb-neutral-color-200: #d8d8d8;
$tb-neutral-color-300: #c3c3c3;
$tb-neutral-color-400: #9b9b9b;
$tb-neutral-color-500: #616c6b;
$tb-neutral-color-600: #494848;
$tb-neutral-color-700: #323232;
$tb-neutral-color-800: #181818;
$tb-neutral-color-900: black;

:root {
  --tb-warn-color-50: #{$tb-warn-color-50};
  --tb-warn-color-100: #{$tb-warn-color-100};
  --tb-warn-color-200: #{$tb-warn-color-200};
  --tb-warn-color-300: #{$tb-warn-color-300};
  --tb-warn-color-400: #{$tb-warn-color-400};
  --tb-warn-color-500: #{$tb-warn-color-500};
  --tb-warn-color-600: #{$tb-warn-color-600};
  --tb-warn-color-700: #{$tb-warn-color-700};
  --tb-warn-color-800: #{$tb-warn-color-800};
  --tb-warn-color-900: #{$tb-warn-color-900};

  --tb-neutral-color-50: #{$tb-neutral-color-50};
  --tb-neutral-color-100: #{$tb-neutral-color-100};
  --tb-neutral-color-200: #{$tb-neutral-color-200};
  --tb-neutral-color-300: #{$tb-neutral-color-300};
  --tb-neutral-color-400: #{$tb-neutral-color-400};
  --tb-neutral-color-500: #{$tb-neutral-color-500};
  --tb-neutral-color-600: #{$tb-neutral-color-600};
  --tb-neutral-color-700: #{$tb-neutral-color-700};
  --tb-neutral-color-800: #{$tb-neutral-color-800};
  --tb-neutral-color-900: #{$tb-neutral-color-900};
}
