@import 'core';

@import '../theme/tb/theme';
@import '../theme/ve/theme';

body {
  margin: 0;
}

header {
  padding: 1rem;

  div {
    max-width: 1580px;
    margin: 0 auto;

    img {
      display: none;
      height: 2.75rem;
    }
  }
}

main {
  padding: 2rem;
  text-align: center;

  p {
    max-width: 50rem;
    margin: 0 auto 1rem;
  }
}

.dialog-safari-closed{
  display: none;
  visibility: hidden;
  width: 0;
  height: 0;
}

.dialog-safari-open{
  display: block;
  visibility: visible;
  position: fixed;
  inset-block-start: 0px;
  inset-block-end: 0px;
  max-height: calc(100% - 2em - 6px);
  user-select: text;
  overflow: auto;
}

dialog#unsupported {
  padding: 0;
  outline: 0;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.25rem;
  max-width: 50rem;

  #unsupported-title {
    color: $neutral-color-50;
    background-color: $primary-color;
    display: flex;
    align-items: center;
    font-size: 1rem;
    padding: 0.25rem 3rem 0.25rem 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  #dialog-link-container {
    display: flex;
    justify-content: center;
    padding-bottom: 1rem;
  }
}

.btn {

  font-weight: 700;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  text-transform: uppercase;
  font-size: 14px;

  &.btn-secondary {
    color: $neutral-color-50 ;
    background-color: $accent-color;
  }

  &:hover,
  &:focus {
    text-decoration: none;
    box-shadow: 0 0 0 0 !important;
  }
}